<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid py-8">
      <div class="d-flex flex-row">
        <UserNav activePage="admincoaches" activeTab="2"></UserNav>
        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">
          <!--begin::Content-->
          <div class="flex-row-fluid ">
            <div class="row">
              <div class="col-xl-7">
                <ListWidget4></ListWidget4>
              </div>
              <div class="col-xl-5">
                <ListWidget10></ListWidget10>
              </div>
            </div>
            <!-- <div class="row">
                <div class="col-xl-12">
                    <vue-event-calendar :events="adminCoachDates"></vue-event-calendar>
                </div>
            </div> -->
            <div>
              <div class="card card-custom gutter-b card-stretch card-shadowless">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark fs-15">
                      <h3 class="font-weight-bolder text-dark fs-15">Upcoming Sessions</h3>
                    </span>
                    <span class="text-dark mt-3 font-weight-bold font-size-sm">
                      All times are in EST
                    </span>
                  </h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-3">
                  <vue-event-calendar :events="adminCoachDates"></vue-event-calendar>
                </div>
                <!--end::Body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget10 from "@/view/content/widgets/list/Widget10.vue";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: 'admincoaches',
  components: {
    ListWidget4,
    ListWidget10,
    UserNav
  },
  mounted() {
    this.$store.dispatch("getAdminCoachDashboardSchedule");
  },
  computed: {
    ...mapGetters(["adminCoachDates"]),
  },
}
</script>

<style>
.btn-ingomublue {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-ingomugreen {
  color: #fff;
  background-color: #46c346;
  border-color: #46c346;
}

.text-black {
  color: #000000 !important;
}

.header-fixed.header-bottom-enabled .__vev_calendar-wrapper .wrapper {
  padding-top: 0 !important;
}

.__vev_calendar-wrapper {
  max-width: 100%;
}

.__vev_calendar-wrapper .cal-wrapper {
  width: 60%;
}

.__vev_calendar-wrapper .events-wrapper {
  width: 40%;
  left: 60%;
}

.__vev_calendar-wrapper .events-wrapper .event-item .title {
  font-size: 14px !important;
}

.__vev_calendar-wrapper .events-wrapper .event-item .time {
  font-size: 13px !important;
}

.__vev_calendar-wrapper .events-wrapper .event-item .desc {
  font-size: 13px !important;
}

.__vev_calendar-wrapper .events-wrapper .date {
  font-size: 1.275rem;
}

.__vev_calendar-wrapper .cal-wrapper {
  padding: 0 100px 50px 100px;
}

.__vev_calendar-wrapper .events-wrapper .event-item .time {
  display: none;
}

/* .__vev_calendar-wrapper .events-wrapper .event-item .title {
        display: none;
      } */

.canceled .date-num {
  color: red !important;
}

.canceled .is-event {
  border-color: red !important;
}

.scheduled .date-num {
  color: #46c346 !important;
}

.scheduled .is-event {
  border-color: #46c346 !important;
}
</style>
