<template>
  <!--begin::Base Table Widget 3-->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark fs-15">
          <h3 class="font-weight-bolder text-dark fs-15">Latest Sessions</h3>
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0" v-if="latestSessionsList.length > 0">
      <!--begin::Table-->
      <div class="table-responsive">
        <b-table id="latest-sessions-table" :items="latestSessionsList" :fields="fields" show-empty
          thead-class="hidden_header"
          class="table table-borderless table-vertical-center h-100 w-100 obcover br-42 align-self-center">
          <template #cell(image)="data" class="pl-0 py-4">
            <div class="symbol symbol-50 symbol-light mr-1">
              <span class="symbol-label">
                <router-link :to="{
                  name: 'adminsessiondetail',
                  params: { coachlistid: data.item.coach_list_id, sessionId: data.item.id },
                }" class="symbol-label text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                  <img :src="imageSource(data.item.coach.profileimage)"
                    class="h-100 w-100 obcover br-42 align-self-center" alt="" />
                </router-link>
              </span>
            </div>
          </template>
          <template #cell(name)="data" class="pl-0 py-4">
            <router-link :to="{
              name: 'adminsessiondetail',
              params: { coachlistid: data.item.coach_list_id, sessionId: data.item.id },
            }" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {{ data.item.coach.first }} {{ data.item.coach.last }}
            </router-link>
          </template>
          <template #cell(date)="data" class="pl-0 py-4">
            <router-link :to="{
              name: 'sessiondetail',
              params: { sessionId: data.item.id },
            }">
              {{ shortFormatDate(data.item.datetime) }}
            </router-link>
          </template>
          <template #cell(status)="data" class="pl-0 py-4">
            <button @click="changeOnlineStatus(false, data)" v-if="data.item.online == true"
              class="label label-lg label-inline label-light-success mr-2 mt-2" :disabled="clicked.includes(data.item.id)">
              Online
            </button>
            <button @click="changeOnlineStatus(true, data)" v-else
              class="label label-lg label-inline label-light-danger mr-2 mt-2" :disabled="clicked.includes(data.item.id)">
              Offline
            </button>
            <button @click="changeVisibleStatus(false, data)" v-if="data.item.coachVisible == true"
              class="label label-lg label-inline label-light-success mr-2 mt-2"
              :disabled="visibleClicked.includes(data.item.id)">
              Visible
            </button>
            <button @click="changeVisibleStatus(true, data)" v-else
              class="label label-lg label-inline label-light-danger mr-2 mt-2"
              :disabled="visibleClicked.includes(data.item.id)">
              Hidden
            </button>
            <button type="button" @click="refreshLearners(data.item.coach_list_id, data.item.id)"
              class="label label-lg label-inline label-light-primary mr-2 mt-2">
              Members
            </button>
          </template>
        </b-table>
        <div class="row justify-content-center mt-7 mb-7">
          <div class="col-xl-12">
            <div class="next-prev-btn d-flex align-items-center justify-content-between">
              <div class="prev-btn">
                <a class="btn-link btn-link--prev" href="javascript:void(0)" v-if="prev_all"
                  @click="getPrevBlogList()"><i class="fa fa-arrow-left"></i>prev</a>
              </div>
              <div class="prev-btn">
                <a class="btn-link btn-link--next" href="javascript:void(0)" v-if="next_all"
                  @click="getNextBlogList()">next<i class="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::table-->
    </div>
    <!--begin::Body-->
    <b-modal id="bv-modal-learners" size="lg" scrollable centered hide-footer>
      <template #modal-title>Members</template>
      <div class="d-block text-center">
        <div class="card-body">
          <!--begin::Scroll-->
          <div>
            <!--begin::Messages-->
            <div class="messages">
              <b-table id="session-users-table" :items="adminSessionUsersList" :fields="userfields" show-empty
                :per-page="perPage" :current-page="userCurrentPage"
                class="table table-borderless table-vertical-center h-100 w-100 obcover br-42 align-self-center">
                <template #cell(image)="data" class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img :src="imageSource(data.item.user.profileimage)"
                        class="h-100 w-100 obcover br-42 align-self-center" alt="" />
                    </span>
                  </div>
                </template>
                <template #cell(name)="data" class="pl-0 py-4">
                  <span class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {{ data.item.user.firstname }}
                  </span>
                </template>
                <template #cell(start)="data" class="pl-0 py-4">
                  <div class="d-flex align-items-center py-2">
                    <span class="
                                            text-dark
                                            font-weight-bold
                                            text-hover-primary
                                            font-size-lg
                                            mb-1
                                        "
                                        v-if="data.item.startdatetime">
                      {{ userTimestampToDate(data.item.startdatetime) }}
                    </span>
                  </div>
                </template>
                <template #cell(end)="data" class="pl-0 py-4">
                  <div class="d-flex align-items-center py-2">
                    <span class="
                                            text-dark
                                            font-weight-bold
                                            text-hover-primary
                                            font-size-lg
                                            mb-1
                                        "
                                        v-if="data.item.enddatetime">
                      {{ userTimestampToDate(data.item.enddatetime) }}
                    </span>
                  </div>
                </template>
              </b-table>
              <div class="mt-10">
                <b-pagination v-model="userCurrentPage" :total-rows="userRows" :per-page="perPage" align="left">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        </div>
    </b-modal>
  </div>
  <!--end::Base Table Widget 3-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";
import Swal from "sweetalert2";

export default {
  name: "widget-4",
  mixins: [defaultProfileImageMixin, formateDateMixin],
  data() {
    return {
      perPage: 10,
      fields: [
        { key: "image", label: "" },
        { key: "name", label: "" },
        { key: "date", label: "" },
        { key: "status", label: "" },
      ],
      userfields: [
        { key: "image", label: "" },
        { key: "name", label: "Name" },
        { key: "start", label: "Start Date/Time" },
        { key: "end", label: "End Date/Time" },
      ],
      userCurrentPage: 1,
      clicked: [],
      visibleClicked: []
    }
  },
  mounted() {
    this.$store.dispatch("resetLatestSessionsList");
    this.$store.dispatch("getNextAdminLatestSessionsList", { firstTime: true });
  },
  methods: {
    userTimestampToDate(ts) {
      let newdtt = new Date(ts.seconds * 1000).toLocaleString("en-US", {timeZone: "America/New_York"}).toString()
      return `${newdtt} EST`;
    },
    getNextBlogList() {
      this.$store.dispatch("getNextAdminLatestSessionsList", { firstTime: false });
    },
    getPrevBlogList() {
      this.$store.dispatch("getPrevAdminLatestSessionsList");
    },
    changeOnlineStatus(status, data) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3b5998",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.clicked.push(data.item.id)
          data.item.online = status
          this.$store.dispatch("updateCoachOnlineSessionStatus", {
            status: status,
            uid: data.item.coach_list_id,
            sessionid: data.item.id
          });
          Swal.fire("Updated!", "Your online status has been updated.", "success");
        }
      });
    },
    changeVisibleStatus(status, data) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3b5998",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.clicked.push(data.item.id)
          data.item.coachVisible = status
          this.$store.dispatch("updateCoachVisibleSessionStatus", {
            status: status,
            uid: data.item.coach_list_id,
            sessionid: data.item.id
          });
          Swal.fire("Processing!", "Your visiblity status will update shortly.", "success");
        }
      });
    },
    refreshLearners(coachListId, sessionId) {
      this.$store.dispatch("getAdminSessionUsersList", {
        coachid: coachListId,
        sessionid: sessionId
      });
      this.$bvModal.show("bv-modal-learners");
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "latestSessionsList", "next_all", "prev_all", "adminSessionUsersList"]),
    userRows() {
      return this.adminSessionUsersList.length;
    }
  }
};
</script>
