<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark fs-15">Coaches</h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <export-excel class="btn btn-ingomugreen py-2 px-4 active mr-2 btn-sm" :data="adminCoachList"
              :fields="excel_fields" worksheet="Coach List" name="IngomuCoachList.xls">
              Excel
            </export-excel>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'coachsignup' }" class="nav-link btn-ingomublue py-2 px-4 active btn-sm">New
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <div class="mt-5 mb-5">
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-table id="coaches-table" :items="adminCoachList" :fields="fields" :per-page="perPage"
        :current-page="currentPage" show-empty thead-class="hidden_header" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered" class="table table-borderless table-vertical-center">
        <template #cell(image)="data" class="pl-0 py-4">
          <div class="symbol symbol-50 symbol-light mr-1">
            <router-link :to="{ name: 'admincoachdetail', params: { coachId: data.item.id } }"
              class="text-dark text-hover-primary font-weight-bold font-size-lg mb-1">
              <span class="symbol-label">
                <img :src="imageSource(data.item.smallthumbnail)" class="h-100 w-100 obcover br-42 align-self-center"
                  alt="" />
              </span>
            </router-link>
          </div>
        </template>
        <template #cell(name)="data" class="pl-0 py-4">
          <router-link :to="{ name: 'admincoachdetail', params: { coachId: data.item.id } }"
            class="text-dark text-hover-primary font-weight-bold font-size-lg mb-1">
            {{ data.item.coach.first }} {{ data.item.coach.last }}
          </router-link>
        </template>
        <template #cell(category)="data" class="pl-0 py-4">
          <span class="text-dark font-weight-bold">{{ data.item.category[0] }}</span>
        </template>
        <template #cell(status)="data" class="pl-0 py-4">
          <button @click="changeOnlineStatus(false, data)" v-if="data.item.online == true"
            class="label label-lg label-inline label-light-success mr-2 mt-2"
            :disabled="onlineClick.includes(data.item.id)">
            Online
          </button>
          <button @click="changeOnlineStatus(true, data)" v-else
            class="label label-lg label-inline label-light-danger mr-2 mt-2"
            :disabled="onlineClick.includes(data.item.id)">
            Offline
          </button>
          <button @click="changeTestStatus(false, data)" v-if="data.item.isTest == true"
            class="label label-lg label-inline label-light-danger mr-2 mt-2"
            :disabled="testClick.includes(data.item.id)">
            Test
          </button>
          <button @click="changeTestStatus(true, data)" v-else
            class="label label-lg label-inline label-light-success mr-2 mt-2"
            :disabled="testClick.includes(data.item.id)">
            Live
          </button>
        </template>
      </b-table>
      <div class="mt-10">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
        </b-pagination>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import Swal from "sweetalert2";

export default {
  name: "widget-4",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "image", label: "" },
        { key: "name", label: "" },
        { key: "category", label: "" },
        { key: "status", label: "" },
      ],
      filter: null,
      filterOn: [],
      onlineClick: [],
      testClick: [],
      excel_fields: {
        'First Name': 'coach.first',
        'Last Name': 'coach.last',
        'Title': 'title',
        'Category': 'category',
        'Bio': 'coach.bio',
        'Is Test?': 'isTest',
        'Online?': 'online',
        'Description': 'description',
        'Keywords': 'keywords',
        'User ID': 'coach.user_id',
        'Coach List ID': 'id'
      }
    };
  },
  mixins: [defaultProfileImageMixin],
  methods: {
    changeOnlineStatus(status, data) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3b5998",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.onlineClick.push(data.item.id)
          // data.item.online = status
          this.$store.dispatch("updateCoachOnlineStatus", {
            status: status,
            uid: data.item.id
          });
          Swal.fire("Processing!", "Your online status will update shortly.", "success");
        }
      });
    },
    changeTestStatus(status, data) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3b5998",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.testClick.push(data.item.id)
          // data.item.isTest = status
          this.$store.dispatch("updateCoachTestStatus", {
            status: status,
            uid: data.item.id
          });
          Swal.fire("Processing!", "Your test status will update shortly.", "success");
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  mounted() {
    this.$store.dispatch("getAdminCoachList");
  },
  computed: {
    ...mapGetters(["layoutConfig", "adminCoachList"]),
    rows() {
      return this.adminCoachList.length;
    }
  },
};
</script>
